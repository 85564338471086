import { FaEye, FaMedapps } from "react-icons/fa";

import { Title } from "../Title";
import "./Nosotros.css";

export const Nosotros = () => {
  return (
    <div className="Nosotros">
      <Title text="Nosotros" />
      <div className="Nosotros-misionvision">
        <div className="Nosotros-mision bg-blue-core">
          <FaEye size={125} color="white" />
          <div>
            <h3 className="Vision-title">VISION</h3>
            <p className="Nosotros-text">
              Buscamos ser una Agencia Operadora Líder a Nivel Nacional, con una
              sólida formación, ofreciendo la mejor calidad de servicios
              Turísticos, siempre comprometidos en brindarles seguridad,
              Confianza, rescatando las tradiciones de Nuestra Cultura Peruana
              con un enfoque Global Resaltando la responsabilidad Social y
              Sostenibilidad Ambiental.
            </p>
          </div>
        </div>
        <div className="Nosotros-vision bg-blue-core">
          <FaMedapps size={125} color="white" />
          <div>
            <h3 className="Mision-title">MISION</h3>
            <p className="Nosotros-text">
              Nuestra Misión principal es Brindar, servicios de excelencia con
              personal capacitado para garantizar la mejor experiencia de viaje,
              basados en los anhelos de nuestros Clientes, cumpliendo con los
              más altos estándares de calidad, y con todos los protocolos de
              Bioseguridad, responsabilidad social, ambiental del sector
              Turismo.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
