export const Title = ({text}) => {
  return (
    <h2
      className="my-4 md:my-8 text-center text-3xl md:text-4xl text-blue-core
        font-sans font-medium"
    >
      {text}
    </h2>
  );
};
