import { Title } from "../Title";

export const Pagos = ({ type }) => {
  return (
    <>
      <Title text={`Pagos ${type}`} />
      <iframe
        title="pagossoles"
        src={`${
          type === "soles"
            ? "https://pagolink.niubiz.com.pe/pagoseguro/DESTINOSSONADOSSOL/1525439"
            : "https://pagolink.niubiz.com.pe/pagoseguro/DESTINOSSONADOSDOL/1525007"
        }`}
        allowtransparency="true"
        allowFullScreen=""
        loading="lazy"
        scrolling="no"
        height={window.screen.width < 420 ? "870px" : "800px"}
        style={{
          border: 0,
          padding: 0,
          margin: 0,
          width: `100%`,
        }}
      ></iframe>
    </>
  );
};
