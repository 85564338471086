import { useEffect, useState } from "react";
import { getAllPromociones } from "../services/PromocionesService";
import Carousel from "react-multi-carousel";
import { Title } from "./Title";

export const FullDaysPromociones = () => {
  const [promos, setPromos] = useState([]);

  useEffect(() => {
    getAllPromociones().then((response) => {
      setPromos(response.data);
    });
  }, []);

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 3, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 2, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  };

  return (
    <div>
      <Title text={"Promociones Full Days"} />
      <Carousel
        responsive={responsive}
        draggable={false}
        focusOnSelect={false}
        infinite={true}
        autoPlay={true}
        autoPlaySpeed={3000}
        className="z-10"
      >
        {promos.map((promo, index) => (
          <div
            key={index}
            className="p-8 md:p-12 lg:p-16 xl:p-20 2xl:p-24 md:pt-6 lg:pt-6 xl:pt-6 2xl:pt-6"
          >
            <img
              src={promo.imagen.url}
              alt={promo.nombre}
              className="shadow-2xl"
            />
          </div>
        ))}
      </Carousel>
    </div>
  );
};
