import { AiFillCloseCircle } from "react-icons/ai";
import aoe from "../enums";

export const VideoModal = ({ isOpenVideo, closeVideo }) => {
  return (
    <div
      className={`min-w-screen h-screen animated fadeIn faster fixed 
                  left-0 top-0 flex justify-center items-center inset-0 z-50 outline-none
                  focus:outline-none bg-no-repeat bg-center bg-cover ${isOpenVideo &&
                    "hidden"}`}
      id="modal-id"
    >
      <div className="absolute bg-black opacity-80 inset-0 z-0"></div>
      <div className=" p-2 relative mx-auto my-auto rounded-xl shadow-lg bg-white">
        <button
          className="absolute -right-3 -top-3 bg-white rounded-full"
          onClick={closeVideo}
        >
          <AiFillCloseCircle size="32" color="black" title="close" />
        </button>
        <video
          src={aoe.VIDEO}
          width="800px"
          height="500px"
          autoPlay="autoPlay"
          muted
          controls
          loop
        >
          Error.
        </video>
      </div>
    </div>
  );
};
