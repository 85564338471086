import { Splide, SplideSlide } from '@splidejs/react-splide'
import Slide1 from '../assets/CAJAMARCAslide.jpg'
import Slide2 from '../assets/CHACHAPOYASslide.jpg'
import Slide3 from '../assets/CUSCOslide.jpg'

export const Slider = () => {
  return (
    <div className="z-10">
      <Splide
        options={{
          rewind: true,
          autoplay: true,
          type: 'loop',
          pauseOnHover: false,
        }}
        hasSliderWrapper
        hasAutoplayProgress
      >
        <SplideSlide>
          <div
            className="h-56 md:h-vdo flex justify-center items-center bg-slider"
            style={{
              backgroundImage: `url(${Slide1})`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: '100% 100%',
            }}
          >
            {/*             <img
              src={aoe.DESTINOS_LOGO}
              className="h-28 md:h-48"
              alt="logo destinos sonados"
            /> */}
          </div>
        </SplideSlide>
        <SplideSlide>
          <div
            className="h-56 md:h-vdo lex justify-center items-center bg-slider"
            style={{
              backgroundImage: `url(${Slide2})`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: '100% 100%',
            }}
          >
            {/*             <img
              src={aoe.DESTINOS_LOGO}
              className="h-28 md:h-48"
              alt="logo destinos sonados"
            /> */}
          </div>
        </SplideSlide>
        <SplideSlide>
          <div
            className="h-56 md:h-vdo flex justify-center items-center bg-slider"
            style={{
              backgroundImage: `url(${Slide3})`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: '100% 100%',
            }}
          >
            {/*             <img
              src={aoe.DESTINOS_LOGO}
              className="h-28 md:h-48"
              alt="logo destinos sonados"
            /> */}
          </div>
        </SplideSlide>
      </Splide>
    </div>
  )
}
