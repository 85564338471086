import { GoArrowLeft } from "react-icons/go";
import { useHistory } from "react-router";

import "./NotFound.css";

export const NotFound = () => {
  const history = useHistory();

  return (
    <div className="Notfound">
      <h2 className="Notfound-404">404</h2>
      <p>Esta página no existe.</p>
      <div className="Notfound-back" onClick={() => history.push("/")}>
        <GoArrowLeft /> <p>Regresar al inicio</p>
      </div>
    </div>
  );
};
