import { useEffect, useState } from "react";
import { FaSearch } from "react-icons/fa";
import { City } from "../components/cities/City";
import { Slider } from "../components/Slider";
import { getAllCities } from "../services/CiudadService";
import { Title } from "../components/Title";

import "./Nacionales.css";

export const Nacionales = () => {
  const [cities, setCities] = useState([]);
  const [citiesFiltred, setCitiesFiltred] = useState([]);

  useEffect(() => {
    fetchCities();
  }, []);

  const fetchCities = async () => {
    const { data } = await getAllCities();
    setCities(data);
    setCitiesFiltred(data);
  };

  function filterByText(text) {
    const new_cities = cities.filter((citie) =>
      citie.nombre.toLowerCase().includes(text.toLowerCase())
    );
    setCitiesFiltred(new_cities);
  }

  return (
    <div className="flex flex-col items-center m-height">
      <Slider />
      <Title text={"Destinos Nacionales"} />
      <div className="Browser">
        <div
          onClick={() => {
            document.getElementById("browser-input").focus();
          }}
        >
          <FaSearch className="text-blue-core" />
        </div>
        <input
          type="text"
          placeholder="Buscar"
          className="Browser-input"
          id="browser-input"
          onChange={(ev) => filterByText(ev.target.value)}
        />
      </div>
      <div
        className="m-height flex justify-around flex-col
                    flex-wrap items-center md:flex-row md:w-2/3"
      >
        {citiesFiltred &&
          citiesFiltred.map((city, index) => (
            <City key={`city-key-${city.idciudad}-${index}`} city={city} />
          ))}
      </div>
    </div>
  );
};
