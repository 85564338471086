import { useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import { Title } from "../Title";

export const FullDay = ({ fullday }) => {
  const [numPagese, setNumPagese] = useState([]);
  const [isPhone, setIsPhone] = useState(false);

  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

  const concatFunc = (num) => {
    let aea = [];

    for (let i = 0; i < num; i++) {
      setNumPagese(aea.push(i));
    }

    setNumPagese(aea);
  };

  return (
    <div className="flex flex-col justify-center items-center m-height">
      <Title text={fullday.nombre} />
      <Document
        file={fullday.itinerario.url}
        className="flex flex-col items-center"
        onLoadSuccess={({ numPages }) => {
          concatFunc(numPages);
        }}
        error={""}
        onLoadError={() => {
          setIsPhone(true);
        }}
      >
        {numPagese.map((x, i) => (
          <Page
            pageNumber={i + 1}
            key={`key-list-page-${i}`}
            width={window.screen.width < 420 ? "390" : "800"}
          ></Page>
        ))}
      </Document>
      {isPhone && (
        <p className="text-center text-gray-600 my-4">
          Lo sentimos, su navegador no soporta pdfs embebidos dentro de la web.
          <br />
          <br />
          Sin embargo puede revisar nuestro itinerario haciendo clic en "Abrir
          Itinerario"
        </p>
      )}
      <a
        target={window.screen.width > 640 ? "_blank" : ""}
        rel="noreferrer"
        href={fullday.itinerario.url}
        download={fullday.itinerario.nombre}
        className="bg-blue-core shadow-md p-4 rounded-full w-52 text-center
           text-white font-mono font-bold my-8"
      >
        {window.screen.width > 640
          ? "Descargar Itinerario"
          : "Abrir Itinerario"}
      </a>
    </div>
  );
};
