import { Link, useRouteMatch } from "react-router-dom";

export const City = ({ city }) => {
  const { imagen, nombre } = city;
  const match = useRouteMatch();

  return (
    <div>
      <Link
        to={`${match.url === "/" ? "/paquetes" : match.url}/${nombre}`}
        className="shadow p-2 my-4 flex items-center justify-center
               flex-col hover:shadow-lg transition ease-in duration-100 
               bg-gray-100 w-card"
      >
        <img src={imagen.url} alt={imagen.nombre} className="w-80 h-72" />
        <h3 className="text-2xl italic font-serif font-medium py-2">
          {nombre}
        </h3>
      </Link>
    </div>
  );
};
