const aoe = {
  API_URL: "http://localhost:4000/",
  DESTINOS_LOGO:
    "https://storage.googleapis.com/bucket-images-destinos/logo-destinos.png?GoogleAccessId=destinosadmin%40destinos-sonados.iam.gserviceaccount.com&Expires=2703560400&Signature=D26JAWX5KWoxzQte4gXVCXFa7CVYMy4h7axfdFIR7OopefA2NkpTyR%2FJ74UrqBJJO2RXyfF%2FY%2BRU5tqndy00y38stw13d%2B2o%2F15Toves%2B%2FCPhZ0MPNOcSohc64oJIDL1fXnVU32Z4CD2s%2B589iAT3k7WDfLD1%2BZn75EpVBa99Zr%2BpGKecQprjiSZ%2BbfwaVanBbwUwmGupVoGJJAM52bAR4b2WFU6bGFVjDjYv7fnL5PPIPXhSHoWuIoQI%2FPl7c%2Fr4%2FYO9pwAqctPj2obRjdWyKfBD5y1AhI9fPiV9pWLiInZl100dXYyDADzUxj7QV7jI2vY3O4%2Bib%2BRMyZRwGajrA%3D%3D",
  FACEBOOK_LOGO:
    "https://storage.googleapis.com/bucket-images-destinos/Facebook.png?GoogleAccessId=destinosadmin%40destinos-sonados.iam.gserviceaccount.com&Expires=2703560400&Signature=Si2XvJEBMfomwae7q0aCJrE%2F%2B%2FShKQ9czrWxbwCIQ0ua3y3dhHUjlnEkaoGQ3ut9NylfkyG%2Bx6tJKJdGZzpIWCfsxfq365bjfxX1FEYMwLYH6j%2F0ejY4ElJR0zoymQNlli%2BVxZ7Chv3nGIeIMiiHoKVvh7krevsl1BcYLQ8yF02e6e%2FCzEF3gHD0gHtm6XE2xEpXfCOHk555Dme4wplMyPEnXZBsN%2Fzjdahw25AKP8J8YyryAwkF7EAg1rRvCgkX4mqwyKF802DUC0aSN1cGflKwXiIvobFatjtd%2FS82yUEJMyiV656ahKcZmR2AwF4D62JFcPCRtwWG5ONoAhKSYw%3D%3D",
  WSPP_LOGO:
    "https://storage.googleapis.com/bucket-images-destinos/whatsapp.png?GoogleAccessId=destinosadmin%40destinos-sonados.iam.gserviceaccount.com&Expires=2703560400&Signature=AXtSeGkDH3VpgR398OVBzVPFqkBvcvggrGd8a%2Bj8Tba4c65Vnz2wTRKEp4QHJhnWWiHpYlGqWqGjch7qSRlPy1OXch%2F%2FyRsDyNXAyfi80E%2B2oFX6SE%2BzSOIqrDJ0%2F6RSe53ez%2FK9WORrlCMs6JLQRoEQiumWNOjVl2s86vddruzzRIotd1WZiTqoG8XjgKhrXMBgxlD7o9rfir5dVy%2F0brvsxCWPB5afubTJXyZxiuptJdbHNokagZDwBuzKG00icByiBYjVrB65TtxXQzUUwNo1J60judLnsWpO4FsqUJCF%2BxD0JL3zOHj4ivNqaP7u5t%2FCCQapH7jPHsSB2bawHQ%3D%3D",
  INSTAGRAM_LOGO:
    "https://storage.googleapis.com/bucket-images-destinos/Instagram.png?GoogleAccessId=destinosadmin%40destinos-sonados.iam.gserviceaccount.com&Expires=2703560400&Signature=Ba0ovGOt5VEfnfxDuZTyMjnHvJDXKHyPJUQUy5vn%2BFS9pCOOcIQTTAFI%2BCyXnwV765K3xywH0iQJ%2BtG3HPtnLqhmSgs0oBkM5OKFWwmyVFSXldd37fOcKDei1%2FiIOC2cjB8T1L3ZP65fO5W68RKmcLieeW4qzdfg4Wq1uoOyv0vvWx5MecwHzf7yVQx%2FRbMRtN8AKEywNYDMvR7XI5FQZRSiEzRvh8FCkIdifu9UidmzaQgzwCYRLFJw7aVtVAckmucalmCKnmT5k%2FmI10R3OSpHOJVqJ1hPBA%2BL4Xs1koE9eQKE5xKNzs1qpz3afG9UW2oy6DZ2epiSJy1j%2FARsJw%3D%3D",
  TWITTER_LOGO:
    "https://storage.googleapis.com/bucket-images-destinos/Twitter.png?GoogleAccessId=destinosadmin%40destinos-sonados.iam.gserviceaccount.com&Expires=2703560400&Signature=ry5cG2A2CTEKp1QlyP6zokff4yOo4q1s7CdSsVBswQu%2FTwuBgpWxoHX45yN07r4WrUccqEjbmQqOB69zLYEHpqr1tqD2XnSyR2iHUcyzD1Wn9iwfjbeJZNEK6fqMDRGoCdvjFNAkENIce1DnW0aAodMdpAh8wMnuUk8qVVTMpAQULcLmCKXSfWwlktdBQcS%2BPwymuEFpzEpIdr%2FHoAcztf2P7XTDQ%2BSB71G6Q4bUEkj7W%2FWpSREq%2FPm6jYlZzsM%2BIjP8nvGZTuEPmLBWgCkQ3G4mO%2F89YsjyLFb2paM33dLHC5m2k2DTquOWbOXsWh6ECwzln7IJ2AZTpvL78RXD1A%3D%3D",
  YOUTUBE_LOGO:
    "https://storage.googleapis.com/bucket-images-destinos/Youtube.png?GoogleAccessId=destinosadmin%40destinos-sonados.iam.gserviceaccount.com&Expires=2703560400&Signature=vPJIPF6eWfFy4bxlGH6Rp2bFgepUu66Z5Iyy9Llv3F6Hk%2BIHE8ucpF8nRfNoQzytbHLIOLpfk%2FE7lgm7%2FGsjzUHSxN85BIB82sPuYCFhvkusQn9kOuhQUj%2FVEl2a%2FHrF6n1GmPDrg68Q1M3ajeyN%2FwMdmDTH6%2BHedT71585zC1WRVo0E6an9PZ6aUhAAB8ivIUXyE43CvpvVTKmzT%2BOt74lEVybj%2F%2FIbCa3zgDcxWzR9rORtFmMx3E4jQuVzAQRqHyxmIjYedRKS6f11dx2nOamHtuIlJMbTZwxX5SuXzdwsH4PVp8WX3EVsd7BL4T2X0G8x3j84EDZTqTj4I2IQFg%3D%3D",
  PROMPERU_LOGO:
    "https://storage.googleapis.com/bucket-images-destinos/promperu%20%282%29.png?GoogleAccessId=destinosadmin%40destinos-sonados.iam.gserviceaccount.com&Expires=2703560400&Signature=NewRdpQmHlh4li3dH9CxhasFULva14HjHtqEvwtZv7i%2Bor5%2FEZmpviSyKSuEV3dAeM5wyeIfimiZ%2BTI%2FsXdJCkxODSw%2F8d%2B86tw%2FcXJbtbl%2BZMypaWVvjrYzrPHLHjzhrtT0zhOpJG%2BPUglSmG04HSWqH%2BMh1%2BcpyA4uZI7zQxu2%2F12LZAUSzlHWWyp3jzmilfuU0nkMydf%2FtcSTSld0YK1pZsGWBAUmimpTDbfXBDczxLkkN2aSvSJZjp%2FcN5taI9zcpwtEp%2FBhtnSfycRPJzAIUT08egPz3twt8A8FeIVFEueILwTos2umODSRpXu7PEkZ5%2FDMEei9MXo9pQQt1A%3D%3D",
  CALLAO_LOGO:
    "https://storage.googleapis.com/bucket-images-destinos/Escudo_Regi%C3%B3n_Callao.png?GoogleAccessId=destinosadmin%40destinos-sonados.iam.gserviceaccount.com&Expires=2703560400&Signature=HG7U%2BIpI2shA22bzjO%2BWkMaLmUo5BYfKsOjytUHrO0PCbqOK%2BV4jJg%2By5mrPGamtLR6JNzhQNATbDznaYCThkWtFbgDTdKR%2BmWLilq%2Fj5FLLL9mmCrJu3LnRg3DkGHQ4j8z1xRqlsi4edjp4NKZJ9B5MDCb6NeuBKJWM%2FDKiyQp%2FMtJM68ks4HlJ4ILxm7L%2FBhJImbhEpDX%2FJXpa2xkD%2BEGNLP0FGs%2F%2Ffz9RFJ%2F7UO%2FfRSxoSVzFfyXNQX%2B%2B8O8pmf3dwx6w5%2FNFvo%2Bmv%2BTfAjwvNOMeT4CuZnLJOBmkYo0B3iz1l4z%2Bf0qRwoE4CvSDZiTroG8kjtvExBHJ8yEvmw%3D%3D",
  Y_TU_QUE_PLANES_LOGO:
    "https://storage.googleapis.com/bucket-images-destinos/y-tu-que-planes.png?GoogleAccessId=destinosadmin%40destinos-sonados.iam.gserviceaccount.com&Expires=2703560400&Signature=btdafRsFFSjlWqb80PB2YRi4hs9Fmd7b9Jm1YWEKMvInz%2BgBH2FMvrAY3GaWSx%2Fnupvfx5TQcitqVOsZCels63sO85yQmCmuZCiMqK7h2goIcj7ik6aH7nkwN%2Fjts5MGNqSPhbFjWhDtjdvbCq0ycZ0PP%2BC71OF9dc8tfYF5qGp7B19O%2BG4gEIKGjUr%2FAU1EwbMSRTG3epDL93a0mjVh9mlwKDBiiuhJLucwgWZosWMW1Y9HecSQ%2BYqUz9X0FY8CfsrXkGWaUZtithF324JJeDyhkXPVLugF%2BV2d2RkCycxnKluUhrqNFE5TcWLjd0RrrornGyOqMiZX2D%2Be%2BpDpIw%3D%3D",
  MINCETUR_LOGO:
    "https://storage.googleapis.com/bucket-images-destinos/PCM-Comercio-Exterior.png?GoogleAccessId=destinosadmin%40destinos-sonados.iam.gserviceaccount.com&Expires=2703560400&Signature=xtyIsjp8VjYW%2BVv%2Byjuflr0AJ1cgVFnkXrJboSENHZ%2FdoFLhCLFi3%2FThUmC358yAC5xz2W9mt7KNmlwS8LZaFTgW5nmIw%2BHZ%2FIZOSSPIIsrh2Iucy%2FhdAMZKAXU4FZs5DTCsDVoGmdnp88XnV9kkFhrNjeQNEyB0V27VzYI3aYfoeW7nYE2a7I0Py6NyM5OK2Dh9ebCER5SRexEHxD9TtdM0ag5DjYQ9f4%2BfVp95PddiEjMccdaSt8YFprVfz9dYopuXKfSqp0qqh1BxJtO8Monf7yxEjFdHTr6uwPevQTlELCpt8u4IZrUOixA0e%2BzGnLZZjNLQmd6qYnHZGMD%2FWQ%3D%3D",
  VIDEO:
    "https://storage.googleapis.com/bucket-images-destinos/VIDEO%20RESUMEN%20DESTINOS%20SONADOS.mp4?GoogleAccessId=destinosadmin%40destinos-sonados.iam.gserviceaccount.com&Expires=2703560400&Signature=DGvVLHn%2BOLC9mkpobi4KC%2FwpPQY3ZkPf%2BESCy3NtD19z%2FCWsM2q8ghXOWBW12WTbCAPQDqmOSxCEopQ%2FH2HekruZAnTsGSO0d8gkjv4Y5n6idxxoDB9oUkEqYWmdyOJIEcwqKgakOXMueXijsImNjlLUMAIQfc0g22zX2PBSjv7%2FBnxu8UC3AqV9bOphrJonDkp4tYdhWtu9pVdlMI6xIVbWuYR9dMlUd10VqrvDy0TnjG%2FGdx59RZdDcl0CbB59u3endVBVs634kPyy7DviIsDYo%2FcUhlKxBvKKiPdWoRVJTijoDZoBhKA2OBXqFk%2BYGOtbAte2ZM8JV89B3mqEQA%3D%3D",
  DISTINTIVO:
    "https://storage.googleapis.com/bucket-images-destinos/distintivo.png?GoogleAccessId=destinosadmin%40destinos-sonados.iam.gserviceaccount.com&Expires=2703560400&Signature=Ch98o5lqnCb9TyBCzZHf6rmc0Z%2BwRx0xortIFTyRtsV%2FvzWWOv%2BGKS5m1pMYk5zoiQQQCm7T1pkO2s8IsTnFgPpDdaYOzk9V7m71eM3QJXj9KuOsZZZ%2BpuqoRpLgFK%2FWbAcSMxCu6sw32IsuwUDtVy3R4We%2BRatdKoiM8TZpumYm1WwKuSEnlRGaXvxqJS1FxH6%2FbyLvPS6NBR8k9FbGsHQDCc9Bs2Wu6WLHpEBifW5AN3hbb%2FW513XW1163l4kAe9fML1pj5jShthXxf9fpDcGZfTyYGSjdTvx66%2B78qIdC4eNLe75XESFUNfYEkht0On3qxmuCGUhdcIxYhtzdvQ%3D%3D",
};
export default aoe;
