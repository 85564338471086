import { useEffect, useState } from "react";
import { getFullDays } from "../../services/FullDayService";
import { Title } from "../Title";
import { FullDayCard } from "./FullDayCard";

export const FullDays = () => {
  const [fulldays, setFulldays] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  async function fetchData() {
    const data = await getFullDays();
    setFulldays(data);
  }

  return (
    <div className="m-height flex flex-col items-center">
      <Title text={"Full Days"} />

      <div
        className="flex justify-around flex-col 
                        flex-wrap items-center md:flex-row md:w-2/3"
      >
        {fulldays.length > 0 &&
          fulldays.map((item, index) => (
            <FullDayCard fullday={item} key={index} />
          ))}
      </div>
    </div>
  );
};
