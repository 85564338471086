import { useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";

export const Paquete = ({ paquete }) => {
  const [numPagese, setNumPagese] = useState([]);
  const [isPhone, setIsPhone] = useState(false);

  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

  const concatFunc = (num) => {
    let aea = [];

    for (let i = 0; i < num; i++) {
      setNumPagese(aea.push(i));
    }

    setNumPagese(aea);
  };

  return (
    <div className="flex flex-col justify-center items-center m-height">
      {paquete ? (
        <>
          <div className="my-4 md:my-8">
            <h2
              className="text-center text-3xl md:text-4xl text-blue-core
        font-sans "
            >
              {paquete.nombre}
            </h2>
            <h3 className="text-center text-gray-500 font-mono text-xl">
              {`${paquete.dias.toLocaleString("en-US", {
                minimumIntegerDigits: 2,
                useGrouping: false,
              })} Dias/${paquete.noches.toLocaleString("en-US", {
                minimumIntegerDigits: 2,
                useGrouping: false,
              })}
              Noches`}
            </h3>
          </div>

          <Document
            file={paquete.itinerario.url}
            className="flex flex-col items-center"
            onLoadSuccess={({ numPages }) => {
              concatFunc(numPages);
            }}
            error={""}
            onLoadError={() => {
              setIsPhone(true);
            }}
          >
            {numPagese.map((x, i) => (
              <Page
                pageNumber={i + 1}
                key={`key-list-page-${i}`}
                width={window.screen.width < 420 ? "390" : "800"}
              ></Page>
            ))}
          </Document>
        </>
      ) : (
        ""
      )}
      {isPhone && (
        <p className="text-center text-gray-600 my-4">
          Lo sentimos, su navegador no soporta pdfs embebidos dentro de la web.
          <br />
          <br />
          Sin embargo puede revisar nuestro itinerario haciendo clic en "Abrir
          Itinerario"
        </p>
      )}
      {paquete && (
        <a
          target={window.screen.width > 640 ? "_blank" : ""}
          rel="noreferrer"
          href={paquete.itinerario.url}
          download={paquete.itinerario.nombre}
          className="bg-blue-core shadow-md p-4 rounded-full w-52 text-center
           text-white font-mono font-bold my-8"
        >
          {window.screen.width > 640
            ? "Descargar Itinerario"
            : "Abrir Itinerario"}
        </a>
      )}
    </div>
  );
};
