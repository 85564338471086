import { useEffect, useState } from "react";
import { Switch, Route } from "react-router-dom";
import { getAllNames } from "../../services/CiudadService";
import { Nacionales } from "../../layouts/Nacionales";
import { Paquetes } from "../paquetes/Paquetes";
import { Inicio } from "../../layouts/Inicio";
import { getAllPaquetes } from "../../services/PaqueteService";
import { Paquete } from "../paquetes/Paquete";
import { Pagos } from "../pagos/Pagos";
import { FullDays } from "../fulldays/FullDays";
import { FullDay } from "../fulldays/FullDay";
import { getFullDays } from "../../services/FullDayService";
import { Nosotros } from "../nosotros/Nosotros";
import { NotFound } from "../notfound/NotFound";
import { MercadoPago } from "../mercadopago/MercadoPago";

export const PaqueteRoutes = () => {
  const [citiesNames, setCitiesNames] = useState([]);
  const [paquetes, setPaquetes] = useState([]);
  const [fulldays, setFulldays] = useState([]);
  const [isOpenVideo, setIsOpenvideo] = useState(false);

  useEffect(() => {
    fetchCitiesNames();
    fetchPaquetes();
    fetchFulldays();
  }, []);

  const fetchCitiesNames = async () => {
    const { data } = await getAllNames();
    setCitiesNames(data);
  };

  const fetchPaquetes = async () => {
    const { data } = await getAllPaquetes();
    setPaquetes(data);
  };

  const fetchFulldays = async () => {
    const fulldays = await getFullDays();
    setFulldays(fulldays);
  };

  const closeVideo = () => {
    setIsOpenvideo(true);
  };

  return (
    <Switch>
      {citiesNames.map((ciudad, index) => {
        return paquetes.map(
          (paquete, index) =>
            paquete.ciudad.nombre === ciudad.nombre && (
              <Route
                path={`/paquetes/${paquete.ciudad.nombre}/${paquete.nombre}`}
                key={`uk-pak-citi-key-${index}`}
              >
                <Paquete paquete={paquete} />
              </Route>
            )
        );
      })}
      {citiesNames.map((ciudad, index) => (
        <Route
          exact
          path={`/paquetes/${ciudad.nombre}`}
          key={`uk-pak-${index}`}
        >
          <Paquetes nombre={ciudad.nombre} paquetes={paquetes} />
        </Route>
      ))}
      <Route exact path="/paquetes">
        <Nacionales />
      </Route>
      {fulldays?.length > 0 &&
        fulldays.map((item, index) => (
          <Route exact path={`/fulldays/${item.nombre}`} key={index + "path"}>
            <FullDay fullday={item} />
          </Route>
        ))}
      <Route exact path="/fulldays" component={FullDays} />
      <Route exact path="/nosotros" component={Nosotros} />
      <Route exact path="/pagos/soles" children={Pagos({ type: "soles" })} />
      <Route
        exact
        path="/pagos/dolares"
        children={Pagos({ type: "dólares" })}
      />
      <Route exact path="/mercadopago" component={MercadoPago} />
      <Route exact path="/">
        <Inicio isOpenVideo={isOpenVideo} closeVideo={closeVideo} />
      </Route>
      <Route exact path="/*" component={NotFound} />
    </Switch>
  );
};
