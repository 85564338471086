import ChatBot from 'react-simple-chatbot'
import { ThemeProvider } from 'styled-components'
import aoe from '../../enums'
import { Redirect } from 'react-router'

export const BotChat = () => {
  const theme = {
    background: window.screen.width > 420 ? '#181818e0' : '#f5f8fb',
    fontFamily: 'Helvetica Neue',
    headerBgColor: '#0F6FB9',
    headerFontColor: '#fff',
    headerFontSize: '15px',
    botBubbleColor: '#0F6FB9',
    botFontColor: '#fff',
    userBubbleColor: '#aeecae',
    userFontColor: '#0F6FB9',
  }
  return (
    <ThemeProvider theme={theme}>
      <ChatBot
        botAvatar={aoe.DESTINOS_LOGO}
        headerTitle="DestinosBot"
        floating
        floatingStyle={{
          height: window.screen.width < 420 ? '51px' : '71px',
          width: window.screen.width < 420 ? '51px' : '71px',
          backgroundImage: aoe.DESTINOS_LOGO,
        }}
        placeholder="Escribir un mensaje..."
        steps={[
          {
            id: '1',
            message:
              'Hola!, Bienvenid@ a Destinos Soñados, nos gustaria ayudarte, ¿Cual es tu nombre?',
            trigger: '2',
          },
          {
            id: '2',
            placeholder: 'Ingrese su nombre por favor..',
            user: true,
            validator: (value) => {
              if (!String(value).match('^[a-zA-Z ,]+$')) {
                return 'Ingrese su nombre por favor..'
              }
              return true
            },
            trigger: '3',
          },
          {
            id: '3',
            message:
              'Hola {previousValue}, encantado de conocerte! Actualmente contamos con paquetes de distintas ciudades, te mostrare las recomendadas y tu me dices cual te interesa, de acuerdo?',
            trigger: '4',
          },
          {
            id: '4',
            options: [
              { value: 'Arequipa', label: 'Arequipa', trigger: '5' },
              { value: 'Cusco', label: 'Cusco', trigger: '20' },
            ],
          },
          {
            id: '5',
            message: 'Estos son los paquetes para la ciudad de {previousValue}',
            trigger: '7',
          },
          {
            id: '7',
            options: [
              {
                value: 'Arequipa Clasico',
                label: 'Arequipa Clasico',
                trigger: '8',
              },
            ],
          },
          {
            id: '8',
            component: (
              <div>
                <Redirect to="/paquetes/arequipa/Arequipa Clasico" />
              </div>
            ),
            trigger: '9',
          },
          {
            id: '9',
            message:
              "Te redirigimos al paquete 'Arequipa Clasico', buen viaje! ;) ",
          },
          {
            id: '20',
            message: 'Estos son los paquetes para la ciudad de {previousValue}',
            trigger: '10',
          },
          {
            id: '10',
            options: [
              {
                value: 'Cusco Clasico 4 Dias/ 3 Noches',
                label: 'Cusco Clasico 4 Dias/ 3 Noches',
                trigger: '11',
              },
              {
                value: 'Cusco con Montaña de 7 colores 4 Dias/ 3 Noches',
                label: 'Cusco con Montaña de 7 colores 4 Dias/ 3 Noches',
                trigger: '12',
              },
              {
                value: 'Cusco 5 Dias/ 4 Noches',
                label: 'Cusco 5 Dias/ 4 Noches',
                trigger: '15',
              },
              {
                value:
                  'Cusco Completo Noche en Aguas Calientes 8 Dias/ 7 Noches',
                label:
                  'Cusco Completo Noche en Aguas Calientes 8 Dias/ 7 Noches ',
                trigger: '16',
              },
              {
                value: 'Cusco con Laguna Humantay 6 Dias/ 5 Noches',
                label: 'Cusco con Laguna Humantay 6 Dias/ 5 Noches',
                trigger: '17',
              },
            ],
          },
          {
            id: '11',
            component: (
              <div>
                <Redirect to="/paquetes/cusco/Cusco Clasico" />
              </div>
            ),
            trigger: '13',
          },
          {
            id: '12',
            component: (
              <div>
                <Redirect to="/paquetes/cusco/Cusco con Montaña de 7 colores" />
              </div>
            ),
            trigger: '14',
          },
          {
            id: '15',
            component: (
              <div>
                <Redirect to="/paquetes/cusco/Cusco" />
              </div>
            ),
            trigger: '30',
          },
          {
            id: '16',
            component: (
              <div>
                <Redirect to="/paquetes/cusco/Cusco Completo Noche en Aguas Calientes" />
              </div>
            ),
            trigger: '31',
          },
          {
            id: '17',
            component: (
              <div>
                <Redirect to="/paquetes/cusco/Cusco con Laguna Humantay" />
              </div>
            ),
            trigger: '32',
          },
          {
            id: '13',
            message:
              "Te redirigimos al paquete 'Cusco Clasico', buen viaje! ;) ",
          },
          {
            id: '14',
            message:
              "Te redirigimos al paquete 'Cusco con Montaña de 7 colores', buen viaje! ;) ",
          },
          {
            id: '30',
            message: "Te redirigimos al paquete 'Cusco', buen viaje! ;) ",
          },
          {
            id: '31',
            message:
              "Te redirigimos al paquete 'Cusco Completo Noche en Aguas Calientes', buen viaje! ;)",
          },
          {
            id: '32',
            message:
              "Te redirigimos al paquete 'Cusco con Laguna Humantay', buen viaje! ;) ",
          },
        ]}
      />
    </ThemeProvider>
  )
}
