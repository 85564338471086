import aoe from "../enums.js";
import { FaEnvelope, FaPhoneAlt } from "react-icons/fa";
import Cert from "../assets/n1.png";
import MinCe from "../assets/n2.png";
import YtuQ from "../assets/n3.png";
import Peru from "../assets/n4.png";
import SafeTravels from "../assets/n5.png";
import PromPeru from "../assets/n6.png";

export const Footer = () => {
  return (
    <footer className="bg-blue-core flex flex-col items-center justify-center">
      <div className="flex flex-wrap p-4 w-full">
        <div className="flex flex-col lg:pl-4 xl:pl-8 2xl:pl-32 lg:w-5/12">
          <h3 className="font-bold tracking-wider text-xl py-1 ">Contacto</h3>
          <div className="flex flex-col md:flex-row justify-start">
            <div>
              <h4 className="italic text-yellow-200">Telefonos</h4>
              <br />
              <p className="text-md text-gray-100 tracking-wider xl:tracking-widest flex items-center gap-x-2">
                <FaPhoneAlt /> <span>01 4576735</span>
              </p>
              <br />
              <div className="text-md text-gray-100 tracking-wider xl:tracking-widest flex items-center gap-x-2">
                <FaPhoneAlt /> <span>944 572 973</span>
              </div>
              <br />
              <div className="text-md text-gray-100 tracking-wider xl:tracking-widest flex items-center gap-x-2">
                <FaPhoneAlt /> <span>947 238 507</span>
              </div>
              <br />
            </div>
            <div className="md:ml-2 lg:ml-4 xl:ml-8 2xl:ml-16">
              <h4 className="italic text-yellow-200">Correos</h4>
              <br />
              <div className="text-md text-gray-100 italic md:tracking-wider xl:tracking-widest flex items-center gap-x-2">
                <FaEnvelope /> <span>ventas@destinossonados.com</span>
              </div>
              <br />
              <div className="text-md text-gray-100 italic md:tracking-wider xl:tracking-widest flex items-center gap-x-2">
                <FaEnvelope /> <span>info@destinossonados.com</span>
              </div>
            </div>
            <br />
          </div>
        </div>
        <div className="w-full lg:w-7/12 font-bold text-xl">
          <h3>Ubicanos</h3>
          <iframe
            title="google maps destinos sonados"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3902.5002687512733!2d-77.10427498518777!3d-12.009041291492471!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x6b24488070729342!2sDESTINOS%20SO%C3%91ADOS%20SAC!5e0!3m2!1ses!2spe!4v1620082404043!5m2!1ses!2spe"
            style={{
              border: 0,
              width: "100%",
              height: `${window.screen.width > 700 ? "225px" : "300px"}`,
            }}
            allowFullScreen=""
            loading="lazy"
            className="my-2"
          ></iframe>
        </div>
      </div>
      <div className="flex flex-wrap space-y-4 space-x-4 justify-evenly items-center w-full bg-white py-8">
        <h3 className="text-lg text-blue-core italic font-medium">
          EN ALIANZA CON
        </h3>
        <div className="flex flex-wrap space-y-4 space-x-4 justify-evenly items-center w-full">
          <img src={Cert} alt="Agencia de Viajes y Turismo Registrada" />
          <img src={MinCe} alt="MinCetur" />
          <img src={YtuQ} alt="Y Tu Que planes" />
          <img src={Peru} alt="Marca Peru" />
          <img src={SafeTravels} alt="Safe Travels" />
          <img src={PromPeru} alt="Prom Peru" />
        </div>
      </div>
      <div className="w-full bg-blue-foot flex justify-center space-x-12 p-1">
        <a
          href="https://www.facebook.com/AgenciaDeViajesDestinosS"
          target="blank"
        >
          <img
            src={aoe.FACEBOOK_LOGO}
            alt="Facebook Destinos Soñados"
            className="h-10 transform duration-500 hover:rotate-360 hover:scale-125"
          />
        </a>
        <a href="https://www.instagram.com/destinossonados/" target="blank">
          <img
            src={aoe.INSTAGRAM_LOGO}
            alt="Instagram Destinos Soñados"
            className="h-10 transform duration-500 hover:rotate-360 hover:scale-125"
          />
        </a>
        <a href="https://twitter.com/destinossonados" target="blank">
          <img
            src={aoe.TWITTER_LOGO}
            alt="Twitter Destinos Soñados"
            className="h-10 transform duration-500 hover:rotate-360 hover:scale-125"
          />
        </a>
        <a
          href="https://www.youtube.com/channel/UCkyoZB65tqxcJP4tGR7y5BQ"
          target="blank"
        >
          <img
            src={aoe.YOUTUBE_LOGO}
            alt="Youtube Destinos Soñados"
            className="h-10 transform duration-500 hover:rotate-360 hover:scale-125"
          />
        </a>
      </div>
    </footer>
  );
};
