import axios from "../axios";

export const getFullDays = async () => {
  try {
    const { data } = await axios.get("fullday");
    return data;
  } catch (error) {
    console.error(error.message, "Error mientras se obtienen los full days");
  }
};
