import { useEffect, useState } from "react";

import { getFlyers } from "../../services/FlyerService";

import { CarouselLayout } from "../carouselLayout/CarouselLayout";

export const FlyersInternacionales = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const data = await getFlyers();
    const internacionales = data.filter(
      (item) => item.type === "INTERNACIONAL"
    );
    setData(internacionales);
  };
  return <CarouselLayout title={"Flyers Internacionales"} list={data} />;
};
