import { useEffect, useState } from "react";
import { getRecommendedsCities } from "../services/CiudadService";
import { City } from "./cities/City";
import { Title } from "../components/Title";

export const Recomendados = ({ someStuff }) => {
  const [cities, setCities] = useState([]);

  useEffect(() => {
    fetchCities();
  }, []);

  const fetchCities = async () => {
    const { data } = await getRecommendedsCities();
    setCities(data);
  };

  return (
    <>
      <Title text={"Recomendados"} />
      <div className="flex justify-center flex-wrap flex-col items-center space-y-8 md:space-y-0 md:flex-row md:space-x-8">
        {cities.map((city, index) => (
          <City
            key={`cities-list-${city.idciudad}`}
            city={city}
            someStuff={someStuff}
          />
        ))}
      </div>
    </>
  );
};
