import { useEffect } from "react";
import { useMercadopago } from "react-sdk-mercadopago";

export const MercadoPago = () => {
  const mercadopago = useMercadopago.v2(
    "APP_USR-738c63af-84f7-4220-915c-83652958c43d",
    { locale: "es-PE" }
  );

  useEffect(() => {
    if (mercadopago)
      mercadopago.checkout({
        preference: { id: "" },
        render: {
          container: ".cho-container",
          label: "Pay",
        },
      });
  }, [mercadopago]);

  return (
    <div>
      <div className="cho-container"></div>
    </div>
  );
};
