import axios from "../axios"; 

export const getAllCities = () => {
  return axios.get(`ciudad`);
};

export const getAllNames = () => {
  return axios.get(`ciudad/names`);
};

export const getRecommendedsCities = () => {
  return axios.get(`ciudad/recomendados`);
};
