import { Link } from "react-router-dom";
import { useRouteMatch } from "react-router";

export const FullDayCard = ({ fullday }) => {
  const { url } = useRouteMatch();

  return (
    <Link
      className="shadow my-4 flex items-center justify-center
    flex-col hover:shadow-lg transition ease-in duration-100
    bg-gray-100 p-1"
      to={`${url}/${fullday.nombre}`}
    >
      <img
        src={fullday.imagen.url}
        alt={fullday.nombre + " imagen"}
        className="w-72 h-56"
      />
      <h2
        className="text-2xl italic font-serif font-medium
                        py-2 truncate text-center"
      >
        {fullday.nombre}
      </h2>
    </Link>
  );
};
