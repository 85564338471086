import { Header } from "./components/Header";
import { Footer } from "./components/Footer";
import { BrowserRouter as Router } from "react-router-dom";
import { WhatsApp } from "./components/WhatsApp";
import { PaqueteRoutes } from "./components/Routes/Routes";
import { ScrollToTop } from "./components/ScrollToTop";
import { BotChat } from "./components/chatbot/ChatBot";

function App() {
  return (
    <div className="h-auto bg-gray-200">
      <Router>
        <ScrollToTop />
        <Header />
        <div className="h-16 md:h-24 bg-white"></div>
        <PaqueteRoutes />
        <BotChat />
        <WhatsApp />
        <Footer />
      </Router>
    </div>
  );
}

export default App;
