import { useState, useEffect } from "react";

import { CarouselLayout } from "../carouselLayout/CarouselLayout";

import { getFlyers } from "../../services/FlyerService";

export const FlyersNacionales = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const data = await getFlyers();
    const nacionales = data.filter((item) => item.type === "NACIONAL");
    setData(nacionales);
  };

  return <CarouselLayout title={"Flyers Nacionales"} list={data} />;
};
