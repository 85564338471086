import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Logo from '../assets/logo-blue.png'

export const Header = () => {
  const [isDisplay, setIsDisplay] = useState(true)
  const [displayPagos, setDisplayPagos] = useState(false)

  //close the menu if the click is outside the menu
  const closeMenu = (e) => {
    const menu = document.getElementById('menu-pagos')
    if (menu && !menu.contains(e.target)) {
      setDisplayPagos(false)
    }
  }

  useEffect(() => {
    document.addEventListener('click', closeMenu, true)
    return () => {
      document.removeEventListener('click', closeMenu, true)
    }
  }, [])

  return (
    <header className="bg-white fixed w-full shadow-md z-20 select-none Header">
      <nav className="flex flex-col md:flex-row">
        <div className="flex items-center justify-center md:justify-center md:w-1/6">
          <button
            className="ml-4 active:bg-white md:hidden absolute left-0"
            onClick={() => setIsDisplay(!isDisplay)}
          >
            <svg
              className="block h-8 w-8 text-blue-core active:text-blue-core"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 6h16M4 12h16M4 18h16"
              />
            </svg>
          </button>
          <img src={Logo} alt="logo destinos sonados" className="h-16 py-1 sm:h-24 sm:py-2" />
        </div>
        <div
          className={`flex flex-col md:space-x-8 lg:space-x-16 md:items-center md:pr-8 md:justify-end md:flex-row divide-y divide-white divide-opacity-25 md:flex md:divide-y-0 md:w-5/6 ${isDisplay &&
            'hidden'}`}
        >
          <Link
            to="/"
            className="text-blue-core font-medium px-3 py-2 hover:text-blue-foot transition duration-600 bg-blue-100 sm:bg-white"
            onClick={() => {
              setIsDisplay(!isDisplay)
              setDisplayPagos(false)
            }}
          >
            INICIO
          </Link>
          <Link
            to="/nosotros"
            className="text-blue-core font-medium px-3 py-2 hover:text-blue-foot transition duration-600 "
            onClick={() => {
              setIsDisplay(!isDisplay)
              setDisplayPagos(false)
            }}
          >
            NOSOTROS
          </Link>
          <Link
            to="/paquetes"
            className="text-blue-core font-medium px-3 py-2 hover:text-blue-foot transition duration-600 bg-blue-100 sm:bg-white"
            onClick={() => {
              setIsDisplay(!isDisplay)
              setDisplayPagos(false)
            }}
          >
            NACIONALES
          </Link>
          <div id="menu-pagos" className="border-b-2 border-blue-core">
            <h2
              className="text-blue-core font-medium px-3 py-2 hover:text-blue-foot  transition duration-600 cursor-pointer "
              onClick={() => {
                setDisplayPagos(!displayPagos)
              }}
            >
              PAGOS
            </h2>
            <div
              className={`flex flex-col bg-blue-core rounded-md font-medium text-white shadow-xl border border-white absolute transition duration-200 w-full md:w-32 ${!displayPagos &&
                'hidden'}`}
            >
              <Link
                to="/pagos/soles"
                className="hover:text-blue-foot hover:bg-white p-2 rounded"
                onClick={() => {
                  setIsDisplay(!isDisplay)
                  setDisplayPagos(false)
                }}
              >
                SOLES
              </Link>
              <Link
                to="/pagos/dolares"
                className="hover:text-blue-foot hover:bg-blue-foot p-2 rounded"
                onClick={() => {
                  setIsDisplay(!isDisplay)
                  setDisplayPagos(false)
                }}
              >
                DÓLARES
              </Link>
            </div>
          </div>
          <Link
            to="/fulldays"
            className="text-blue-core font-medium px-3 py-2 hover:text-blue-foot bg-blue-100 sm:bg-white"
            onClick={() => {
              setIsDisplay(!isDisplay)
              setDisplayPagos(false)
            }}
          >
            FULL DAYS
          </Link>
          {/*  <Link
            to="/galeria"
            className="text-blue-core font-medium px-3 py-2 hover:text-blue-foot pointer-events-none"
            onClick={() => {
              setIsDisplay(!isDisplay)
              setDisplayPagos(false)
            }}
          >
            GALERIA
          </Link> */}
        </div>
      </nav>
    </header>
  )
}
