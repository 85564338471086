import Carousel from "react-multi-carousel";
import aoe from "../../enums";

import { Title } from "../Title";

export const CarouselLayout = ({ title, list }) => {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 3, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 2, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  };

  const handleClickWsp = ({ nombre, type }) => {
    window.open(
      "https://api.whatsapp.com/send?phone=+51944572973&text=Hola, quisiera solicitar información sobre el paquete " +
        type.toLowerCase() +
        " " +
        nombre +
        "."
    );
  };

  return (
    <div>
      <Title text={title} />
      <Carousel
        responsive={responsive}
        draggable={false}
        focusOnSelect={false}
        infinite={true}
        autoPlay={true}
        autoPlaySpeed={3000}
        className="z-10"
      >
        {list.map((item, index) => (
          <div
            key={"key-carousel-" + title + index}
            className="p-8 md:p-12 lg:p-16 xl:p-20 2xl:p-24 md:pt-6 lg:pt-6 xl:pt-6 2xl:pt-6"
          >
            <img
              src={item.imagen.url}
              alt={item.nombre}
              className="shadow-2xl"
            />
            <div className="p-4 bg-blue-core flex items-center justify-between gap-4 ">
              <p className="text-white">Consultar por Whatsapp</p>
              <img
                onClick={() => handleClickWsp(item)}
                src={aoe.WSPP_LOGO}
                alt="whatsapp"
                className="shadow-xl p-1 h-10 w-10   bg-green-wsp rounded-full cursor-pointer transform duration-300 hover:scale-110"
              />
            </div>
          </div>
        ))}
      </Carousel>
    </div>
  );
};
