import aoe from "../enums";

export const WhatsApp = () => {
  return (
    <a
      href="https://api.whatsapp.com/send?phone=+51947238507&text=Buenos dias, quisiera informacion sobre..."
      target="_blank"
      rel="noreferrer"
      className="fixed right-8 bottom-24 md:my-6 bg-green-wsp rounded-full shadow-2xl p-1 z-20"
      style={{
        height: window.screen.width < 420 ? "51px" : "71px",
        width: window.screen.width < 420 ? "51px" : "71px",
      }}
    >
      <img
        src={aoe.WSPP_LOGO}
        alt="icono de whatsapp png"
        className="transform duration-500 hover:scale-150"
      />
    </a>
  );
};
