import { FullDaysPromociones } from "../components/FullDaysPromociones";
import { FlyersNacionales } from "../components/flyersnacionales/FlyersNacionales";
import { Recomendados } from "../components/Recomendados";
import { VideoModal } from "../components/VideoModal";
import { Separador } from "../components/Separador";
import { Slider } from "../components/Slider";
import { FlyersInternacionales } from "../components/flyersinternacionales/FlyersInternacionales";

export const Inicio = ({ isOpenVideo, closeVideo }) => {
  return (
    <>
      <Slider />
      <Recomendados />
      <Separador />
      <FullDaysPromociones />
      <Separador />
      <FlyersNacionales />
      <Separador />
      <FlyersInternacionales />
      <VideoModal isOpenVideo={isOpenVideo} closeVideo={closeVideo} />
    </>
  );
};
