import React from "react";
import ReactDOM from "react-dom";
import App from "./App.jsx";
import "./index.css";
import "@splidejs/splide/dist/css/themes/splide-default.min.css";
import "react-multi-carousel/lib/styles.css"; 

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);
