import { useEffect, useState } from "react";
import { Title } from "../Title";
import { Link, useRouteMatch } from "react-router-dom";

export const Paquetes = ({ nombre, paquetes }) => {
  const [packs, setPacks] = useState([]);
  const match = useRouteMatch();

  useEffect(() => {
    setPacks(paquetes.filter((pack) => pack.ciudad.nombre === nombre));
  }, [nombre, paquetes]);

  return (
    <div className="m-height flex flex-col items-center">
      <Title text={`Paquetes ${nombre}`} />
      <div
        className="flex justify-around flex-col flex-wrap items-center 
      md:flex-row md:w-3/4 lg:gap-2"
      >
        {packs.length > 0 ? (
          packs.map((paquete, index) => (
            <Link
              title={paquete.nombre}
              to={`${match.url}/${paquete.nombre}`}
              className="shadow my-4 flex items-center justify-center
                          flex-col hover:shadow-lg transition ease-in duration-100
                          bg-gray-100 w-80 p-2"
              key={`key-paquetesage-nat-${index}`}
            >
              <img
                src={paquete.imagen.url}
                alt={paquete.nombre + " img"}
                className="w-68 h-64 md:h-72 m-0"
              />
              <h3 className="text-2xl italic font-serif font-medium py-2 truncate text-center w-72">
                {paquete.nombre}
              </h3>
              <h4 className="text-gray-600">
                {paquete.dias}Dias / {paquete.noches}Noches
              </h4>
            </Link>
          ))
        ) : (
          <p className="text-center text-green-500 font-bold text-lg py-52">
            Aun no actualizamos paquetes para esta ciudad
          </p>
        )}
      </div>
    </div>
  );
};
